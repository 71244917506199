import React, { useEffect, useMemo } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { StepViewHidden } from '../StepViewHidden';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Spinner, SpinnerTypes } from 'wix-ui-tpa/cssVars';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import utils, { getFlatStepsList } from '../../views/utils';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';
import {
  IParticipantSectionsContext,
  useSections,
} from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { useScrollToTopOnMount } from '../../../../../hooks/useScrollToTopOnMount';
import { getTitle } from '../../../../../selectors/step';
import challengeSettings from '../../../../ChallengesPage/settingsParams';
import { useSettings } from '@wix/tpa-settings/react';
import { TextAlignment } from '../../../../ChallengesPage/Settings/challengeSettings/challengeSettings.types';
import { useVideoStepData } from '../../../../../hooks/useVideoStepData';
import { useIsFullWithInViewer } from '../../../../../services/hooks/useIsFullWidthInViewer';
import loadable from '@wix/yoshi-flow-editor/loadable';

import { classes, st } from './SideBarStepView.st.css';

const VideoPlayerInStep: FCWithChildren<any> = loadable(() => {
  return import(/* webpackPrefetch: true */ '../VideoPlayerInStep');
});

export interface ISideBarStepViewProps {
  dataHook?: string;
  challenge: Challenge;
  isListParticipantSectionsRequestInProgress: boolean;
  isParticipantStepsLoading: boolean;
  currentStep: IParticipantSectionsContext['selectedStep'];
  listParticipantSections: IParticipantSectionsContext['listParticipantSections'];
  participantSteps: IParticipantSectionsContext['participantSteps'];
  goToCurrentStep(e: any): void;
  Breadcrumbs: () => React.ReactElement;
  pageRef: React.RefObject<HTMLElement>;
}

export const SideBarStepView: FCWithChildren<ISideBarStepViewProps> = (
  props,
) => {
  const { isMobile } = useEnvironment();
  const {
    challenge,
    isListParticipantSectionsRequestInProgress,
    isParticipantStepsLoading,
    currentStep,
    listParticipantSections,
    participantSteps,
    Breadcrumbs,
    pageRef,
  } = props;
  const stepId = currentStep?.id;
  const {
    requestParticipantStep,
    stepDescriptionLoaded,
    getStepDescriptionById,
  } = useSections();
  const isFullWidthLayout = useIsFullWithInViewer();
  const flatStepsList = useMemo(
    () =>
      getFlatStepsList({
        listParticipantSections,
        participantSteps,
      }),
    [listParticipantSections, participantSteps],
  );
  const contentContainerRef = React.useRef<HTMLDivElement>(null);
  const isStepHidden = utils.isStepHidden(
    challenge,
    flatStepsList,
    // TODO: check why currentStep helped instead of originalStep. was fixing: https://wix.slack.com/archives/CQF1SC6MN/p1705578648983029
    currentStep,
  );

  const challengeStep = currentStep?.source;
  const settings = useSettings();
  const alignment = settings.get(challengeSettings.sidebarLayoutTextAlignment);
  const isRTL = alignment === TextAlignment.Right;
  const [loadingDetailsForStep, setLoadingDetailsForStep] = React.useState('');

  const { stepType } = useVideoStepData(challengeStep);

  useEffect(() => {}, []);

  React.useEffect(() => {
    if (
      loadingDetailsForStep !== stepId &&
      !stepDescriptionLoaded(stepId) &&
      !isStepHidden
    ) {
      setLoadingDetailsForStep(stepId);
      void requestParticipantStep?.(stepId);
    }
  }, [
    isStepHidden,
    requestParticipantStep,
    stepDescriptionLoaded,
    loadingDetailsForStep,
    stepId,
  ]);

  useSingleEndInteraction(FedopsInteraction.ViewStepAtSidebarLayout, stepId);
  useScrollToTopOnMount(contentContainerRef);

  if (!currentStep) {
    return null;
  }

  return isListParticipantSectionsRequestInProgress ||
    isParticipantStepsLoading ? (
    <Spinner type={SpinnerTypes.slim} isCentered={false} />
  ) : (
    <div
      id={stepId}
      className={st(classes.root, {
        mobile: isMobile,
        rtl: isRTL,
        type: stepType.toLowerCase(),
        fullWidthLayout: isFullWidthLayout,
      })}
      data-hook={props.dataHook || null}
      ref={contentContainerRef}
    >
      {!isStepHidden ? (
        <>
          <Breadcrumbs />
          <Fullscreen pageRef={pageRef} />

          <h3 className={classes.stepViewTitle} dir="auto">
            {getTitle(challengeStep)}
          </h3>

          {!stepDescriptionLoaded(stepId) ? (
            <Spinner
              type={SpinnerTypes.slim}
              isCentered={!isMobile}
              className={classes.spinner}
            />
          ) : (
            <>
              <VideoPlayerInStep step={challengeStep} />
              <div className={classes.ricos}>
                <Ricos
                  key={stepId}
                  details={getStepDescriptionById(stepId)}
                  contentId={stepId}
                  context="program_step"
                  dataHook="program-step"
                />
              </div>
            </>
          )}
        </>
      ) : (
        <StepViewHidden
          date={currentStep?.dateFrame?.start}
          goToCurrentStep={(e) => props.goToCurrentStep(e)}
        />
      )}
    </div>
  );
};
